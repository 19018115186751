import {
  CSSObject,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  Interpolation,
  SimpleInterpolation,
  css,
} from 'styled-components';

import { ThemeProps } from '@foodles/ui-components/theme';

export type Size =
  | 'phoneS'
  | 'phoneM'
  | 'phoneL'
  | 'tabletS'
  | 'tabletL'
  | 'laptop'
  | 'laptopL'
  | 'desktop';

export type SizeByDevice = { [deviceSizeName in Size]: number };

type DeviceSelector<P extends ThemeProps> = (
  first: TemplateStringsArray | CSSObject,
  ...interpolations: Interpolation<P>[]
) => FlattenInterpolation<P>;

type DeviceMedia<P extends ThemeProps> = {
  [deviceSizeName in Size]: DeviceSelector<P>;
};

export const sizes: SizeByDevice = {
  phoneS: 360,
  phoneM: 400,
  phoneL: 600,
  tabletS: 720,
  tabletL: 840,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const DEFAULT_BODY_FONT_SIZE: number = 16;

const deviceSizes: Size[] = [
  'phoneS',
  'phoneM',
  'phoneL',
  'tabletS',
  'tabletL',
  'laptop',
  'laptopL',
  'desktop',
];

export const deviceMedia: DeviceMedia<ThemeProps> = deviceSizes.reduce(
  (acc: DeviceMedia<ThemeProps>, deviceSizeName: Size) => {
    acc[deviceSizeName] = ((
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ): FlattenSimpleInterpolation =>
      css`
        @media (max-width: ${sizes[deviceSizeName] /
            DEFAULT_BODY_FONT_SIZE}em) {
          ${css(first, ...interpolations)};
        }
      `) as DeviceSelector<ThemeProps>;
    return acc;
  },
  {} as DeviceMedia<ThemeProps>,
) as DeviceMedia<ThemeProps>;
